<template>
  <div>
    <!--begin::Forgot Password-->
    <div class="login-form login-forgot">

      <div v-if="theme == 'status'">
        <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
          <span class="svg-icon svg-icon-4x" :class="`svg-icon-primary`">
            <!--begin::Svg Icon-->
            <inline-svg :src="`media/svg/icons/Communication/Readed-mail.svg`" :keepDuringLoading="false" />
            <!--end::Svg Icon-->
          </span>
          Check your email
        </h3>
        <p class="text-muted font-weight-bold font-size-h4 pb-5">
          We have sent a link to the change password page and uniq code to your email. Email Sent {{ this.form.email }}
        </p>          
        <b-button @click="$router.push({ name: 'login' }).catch(()=>{})" class="m-1 font-weight-bolder font-size-h6" variant="light">Login</b-button>
      </div>

      <div v-else>
        <!--begin::Title-->
        <div class="pb-5 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Forgotten Password ?</h3>
            <p class="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p>
        </div>
        <!--end::Title-->

        <b-alert :show="(errors ? true : false)" fade>{{ errors }}</b-alert>

        <!--begin::Form-->
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="form-input-group-1"
            label-class="font-size-h6 font-weight-bolder text-dark pt-5"
            label="Email"
            label-for="form-input-1"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="form-input-1"
              name="form-input-1"
              type="text"
              autocomplete="off"
              v-model="$v.form.email.$model"
              placeholder="boy@poultrynesia.com"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div
            class="form-group d-flex flex-wrap pb-lg-0 mb-0"
          >
            <button
              ref="kt_login_forgot_submit"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
            >
              Submit
            </button>

            <button 
              @click="$router.push({ name: 'auth-login' })" 
              type="button" 
              id="kt_login_forgot_cancel" 
              class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              Cancel
            </button>
          
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Forgot Password-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { FORGOTPASSWORD, KILL } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "forgot-password",
  data() {
    return {
      theme: 'form',
      form: {
        email: "",
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      // clear existing errors
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(KILL);
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
        // send login request
        this.$store
          .dispatch(FORGOTPASSWORD, { email })
          // go to which page after successfully login
          .then(() => {
            this.removeSpinner(submitButton)
            this.theme = 'status'
          })
          .catch( err => {
            this.removeSpinner(submitButton)
          });
      // }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
